import {defineCustomElements as jeepSqlite, applyPolyfills} from 'jeep-sqlite/loader'
import {Capacitor} from '@capacitor/core'
import {SQLiteHook, useSQLite} from 'vue-sqlite-hook'
import {CapacitorSQLite, SQLiteConnection, SQLiteDBConnection} from '@capacitor-community/sqlite'
import * as localForage from 'localforage'
import {
  fetchAllReasons,
  fetchAllClients,
  fetchAllDepreciations,
  fetchAllDestinations,
  fetchAllLotNumbers,
  fetchAllObservations,
  fetchAllProducts,
  fetchAllTypes,
  fetchAllWholesalers,
  fetchAllSorting,
  fetchVersions
} from '@/api'

import {DATABASE, schemaToImport} from '@/db/import'
import {
  Client,
  ClientsFilter,
  Depreciation,
  Destination,
  Line,
  LotNumber,
  LotNumberFilter,
  NewRefund,
  Observation,
  Product,
  ProductFilter,
  Reason,
  Sorting,
  Type,
  Wholesaler
} from '@/interfaces'
import {RefundFilter} from '@/store/refund'
import {readFile} from '@/lib/filesystem'

interface Transaction {
  statement: string
  values: any[]
}

async function getConnection() {
  const sqlite: SQLiteHook = useSQLite()
  const ret = await sqlite.checkConnectionsConsistency()
  const isConn = (await sqlite.isConnection(DATABASE)).result
  let db: SQLiteDBConnection
  if (ret.result && isConn) {
    db = await sqlite.retrieveConnection(DATABASE)
  } else {
    db = await sqlite.createConnection(DATABASE, false, 'no-encryption', 1)
  }
  await db.open()
  return db
}

export async function deleteDB(): Promise<void> {
  const db = await getConnection()
  const deleteQuery = 'DELETE FROM sync_data '
  await db.execute(deleteQuery)
}

export async function initDB() {
  applyPolyfills().then(() => jeepSqlite(window))

  const platform = Capacitor.getPlatform()
  const sqlite: SQLiteConnection = new SQLiteConnection(CapacitorSQLite)

  try {
    if (platform === 'web') {
      // Create the 'jeep-sqlite' Stencil component
      const jeepSqlite = document.createElement('jeep-sqlite')
      document.body.appendChild(jeepSqlite)
      await customElements.whenDefined('jeep-sqlite')

      // Initialize the Web store
      await sqlite.initWebStore()
    }

    const result = await sqlite.isJsonValid(JSON.stringify(schemaToImport))
    if (!result.result) {
      console.log(`isJsonValid: "schemaToImport" is not valid`)
    }

    const resJson = await sqlite.importFromJson(JSON.stringify(schemaToImport))
    if (resJson.changes && resJson.changes.changes && resJson.changes.changes < 0) {
      console.log(`importFromJson: "full" failed`)
    }
  } catch (err) {
    console.log(`Error: ${err}`)
    throw new Error(`Error: ${err}`)
  }
}

async function syncTable(
  db: SQLiteDBConnection,
  fun: () => Promise<any>,
  lastVersion: string,
  table: string,
  fields: string[],
  label: string,
  order: number
) {
  const version = await db.query('SELECT * FROM sync_data WHERE table_name = ?', [table])

  if (version.values && (version.values.length === 0 || version.values[0].version < lastVersion)) {
    const results = await fun()
    const deleteQuery = 'DELETE FROM ' + table // TODO: ??

    await db.execute(deleteQuery)

    let transactions: Transaction[] = []

    for (const row of results) {
      const values = []

      for (const field of fields) {
        values.push(row[field])
      }

      transactions.push({
        statement: 'INSERT INTO ' + table + ' VALUES (' + values.map(() => '?').join(',') + ')',
        values: values
      })

      if (transactions.length >= 1000) {
        // TODO: number of inserts
        console.log(`Inserting ${transactions.length} ${label}`)

        try {
          await db.executeSet(transactions)
        } catch (e) {
          console.log(e)
        }

        transactions = []
      }
    }

    if (transactions.length > 0) {
      await db.executeSet(transactions)
    }

    const transaction: Transaction[] = [
      {
        statement: 'INSERT INTO  sync_data VALUES (?, ?, ?, ?)',
        values: [table, lastVersion, label, order]
      }
    ]
    await db.executeSet(transaction)
  }
}

export async function syncMasterData() {
  const db = await getConnection()

  const versions = await fetchVersions()

  // clients
  await syncTable(
    db,
    fetchAllClients,
    versions.client,
    'client',
    ['id', 'code', 'name', 'email', 'division'],
    'Clientes',
    1
  )

  // products
  await syncTable(
    db,
    fetchAllProducts,
    versions.product,
    'product',
    ['id', 'brand', 'capLot', 'depreciation', 'description', 'division', 'ean', 'nationalCode', 'root', 'price'],
    'Productos',
    2
  )

  // lot numbers
  await syncTable(
    db,
    fetchAllLotNumbers,
    versions.lotNumber,
    'lotNumber',
    ['id', 'code', 'ean', 'batch', 'expiration'],
    'Lotes',
    3
  )

  // depreciations
  await syncTable(
    db,
    fetchAllDepreciations,
    versions.depreciation,
    'depreciation',
    ['id', 'name', 'order'],
    'Depreciaciones',
    4
  )

  // types
  await syncTable(db, fetchAllTypes, versions.type, 'type', ['id', 'name', 'order'], 'Tipos', 5)

  // destinations
  await syncTable(db, fetchAllDestinations, versions.destination, 'destination', ['id', 'name', 'order'], 'Destinos', 6)

  // reasons
  await syncTable(db, fetchAllReasons, versions.reason, 'reason', ['id', 'name', 'order'], 'Motivos', 7)

  // sorting
  await syncTable(db, fetchAllSorting, versions.sorting, 'sorting', ['id', 'name', 'order'], 'Clasificaciones', 8)

  // observations
  await syncTable(
    db,
    fetchAllObservations,
    versions.observation,
    'observation',
    ['id', 'name', 'order'],
    'Observaciones',
    9
  )

  // wholesalers
  await syncTable(db, fetchAllWholesalers, versions.wholesaler, 'wholesaler', ['id', 'name', 'order'], 'Mayoristas', 10)

  return await getSyncData()
}

export async function getSyncData() {
  const db = await getConnection()

  const syncData = await db.query("SELECT * FROM sync_data where table_name <> 'laboratory' ORDER BY orderBy ASC")
  const resume = []

  for (const row of syncData.values || []) {
    const count = await db.query(`SELECT COUNT(*) AS count
                                  FROM ${row.table_name}`)

    if (!count.values || !count.values[0].count) {
      continue
    }

    resume.push({
      table: row.table_name,
      count: count.values[0].count,
      version: row.version,
      label: row.label
    })
  }

  return resume
}

export async function getProducts(filters: ProductFilter): Promise<Product[]> {
  const db = await getConnection()

  let query = 'SELECT * FROM product'

  if (filters) {
    if (filters.nc) {
      query += ` WHERE nationalCode LIKE '%${filters.nc}%'`
    }

    if (filters.ean) {
      if (filters.nc) {
        query += ` AND ean LIKE '%${filters.ean}%'`
      } else {
        query += ` WHERE ean LIKE '%${filters.ean}%'`
      }
    }

    if (filters.description) {
      if (filters.nc || filters.ean) {
        query += ` AND description LIKE '%${filters.description}%'`
      } else {
        query += ` WHERE description LIKE '%${filters.description}%'`
      }
    }

    if (filters.division) {
      if (filters.nc || filters.ean || filters.description) {
        query += ` AND division = '${filters.division}'`
      } else {
        query += ` WHERE division = '${filters.division}'`
      }
    }
  }

  query += ' LIMIT 5'

  const products = await db.query(query)

  return products.values || []
}

export async function getLots(filters?: LotNumberFilter): Promise<LotNumber[]> {
  const db = await getConnection()

  let query = 'SELECT * FROM lotNumber'

  if (filters) {
    if (filters.batch) {
      query += ` WHERE batch LIKE '%${filters.batch}%'`
    }

    if (filters.ean) {
      if (filters.batch) {
        query += ` AND ean LIKE '%${filters.ean}%'`
      } else {
        query += ` WHERE ean LIKE '%${filters.ean}%'`
      }
    }
  }

  query += ' LIMIT 5'

  const lots = await db.query(query)

  return lots.values || []
}

export async function getProductByEAN(ean: string): Promise<Product | null> {
  const db = await getConnection()

  const dbsqLiteValues = await db.query(`SELECT *
                                         FROM product
                                         WHERE ean = ${ean}`)

  if (dbsqLiteValues.values && dbsqLiteValues.values.length > 0) {
    return dbsqLiteValues.values[0]
  } else {
    return null
  }
}

export async function getProductByNC(nc: string): Promise<Product | null> {
  const db = await getConnection()

  const dbsqLiteValues = await db.query(`SELECT *
                                         FROM product
                                         WHERE nationalCode = ${nc}`)

  if (dbsqLiteValues.values && dbsqLiteValues.values.length > 0) {
    return dbsqLiteValues.values[0]
  } else {
    return null
  }
}

export async function getClients(filters: ClientsFilter): Promise<Client[]> {
  const db = await getConnection()

  let query = 'SELECT * FROM client'

  if (filters.name || filters.code || filters.division) {
    query += ' WHERE'
  }

  if (filters.name) {
    query += ` name LIKE '%${filters.name}%'`
  }

  if (filters.code) {
    if (filters.name) {
      query += ' AND'
    }

    query += ` code LIKE '%${filters.code}%'`
  }

  if (filters.division) {
    if (filters.name || filters.code) {
      query += ' AND'
    }

    query += ` division = '${filters.division}'`
  }

  query += ' ORDER BY name ASC LIMIT 5'

  const dbsqLiteValues = await db.query(query)

  return dbsqLiteValues.values || []
}

export async function getTypes(): Promise<Type[]> {
  const db = await getConnection()

  const dbsqLiteValues = await db.query('SELECT * FROM type ORDER BY orderBy ASC')

  return dbsqLiteValues.values || []
}

export async function getDestinations(): Promise<Destination[]> {
  const db = await getConnection()

  const dbsqLiteValues = await db.query('SELECT * FROM destination ORDER BY orderBy ASC')

  return dbsqLiteValues.values || []
}

export async function getSortings(): Promise<Sorting[]> {
  const db = await getConnection()

  const dbsqLiteValues = await db.query('SELECT * FROM sorting ORDER BY orderBy ASC')

  return dbsqLiteValues.values || []
}

export async function getDepreciations(): Promise<Depreciation[]> {
  const db = await getConnection()

  const dbsqLiteValues = await db.query('SELECT * FROM depreciation ORDER BY orderBy ASC')

  return dbsqLiteValues.values || []
}

export async function getObservations(): Promise<Observation[]> {
  const db = await getConnection()

  const dbsqLiteValues = await db.query('SELECT * FROM observation ORDER BY orderBy ASC')

  return dbsqLiteValues.values || []
}

export async function getReasons(): Promise<Reason[]> {
  const db = await getConnection()

  const dbsqLiteValues = await db.query('SELECT * FROM reason ORDER BY orderBy ASC')

  return dbsqLiteValues.values || []
}

export async function getWholesalers(): Promise<Wholesaler[]> {
  const db = await getConnection()

  const dbsqLiteValues = await db.query('SELECT * FROM wholesaler ORDER BY orderBy ASC')

  return dbsqLiteValues.values || []
}

export async function getPendingRefunds(filters: RefundFilter): Promise<NewRefund[]> {
  const db = await getConnection()

  let query = 'SELECT * FROM pending_refunds'

  if (filters.text) {
    query += ` WHERE clientName LIKE '%${filters.text}%'`
  }

  if (filters.startDate) {
    if (filters.text) {
      query += ' AND'
    } else {
      query += ' WHERE'
    }

    if (filters.endDate && filters.endDate !== filters.startDate) {
      query += ` datetime BETWEEN '${filters.startDate}' AND '${filters.endDate}'`
    } else {
      query += ` datetime LIKE '%${filters.startDate}%'`
    }
  }

  if (filters.finished !== null && filters.finished) {
    if (filters.text || filters.startDate) {
      query += ' AND'
    } else {
      query += ' WHERE'
    }

    query += ' finished = 1'
  }

  const dbsqLiteValues = await db.query(query)

  const pendingRefunds: NewRefund[] = []
  for (const row of dbsqLiteValues.values || []) {
    pendingRefunds.push({
      ...row,
      authorization: Boolean(row.authorization),
      tagCodes: row.tagCodes ? row.tagCodes.split(',') : [],
      userSignature: {
        filename: row.userSignature ? row.userSignature : null,
        base64: row.userSignature ? await readFile(row.userSignature) : null
      },
      clientSignature: {
        filename: row.clientSignature ? row.clientSignature : null,
        base64: row.clientSignature ? await readFile(row.clientSignature) : null
      },
      attachment1: {
        filename: row.attachment1 ? row.attachment1 : null,
        base64: row.attachment1 ? await readFile(row.attachment1) : null
      },
      attachment2: {
        filename: row.attachment2 ? row.attachment2 : null,
        base64: row.attachment2 ? await readFile(row.attachment2) : null
      },
      attachment3: {
        filename: row.attachment3 ? row.attachment3 : null,
        base64: row.attachment3 ? await readFile(row.attachment3) : null
      }
    })
  }

  return pendingRefunds
}

export async function getNewRefundLines(newRefundId: number): Promise<Line[]> {
  const db = await getConnection()

  const dbsqLiteValues = await db.query('SELECT * FROM pending_refunds_lines WHERE idRefund = ?', [newRefundId])

  return dbsqLiteValues.values || []
}

export async function getLaboratory(): Promise<any | null> {
  const db = await getConnection()

  const dbsqLiteValues = await db.query("SELECT * FROM sync_data WHERE table_name = 'laboratory'")

  return dbsqLiteValues.values?.[0]
}

export async function setLaboratory(laboratory: string): Promise<void> {
  const db = await getConnection()

  const transaction: Transaction[] = [
    {
      statement: 'INSERT INTO  sync_data VALUES (?, ?, ?, ?)',
      values: ['laboratory', 1, laboratory, 0]
    }
  ]
  await db.executeSet(transaction)
}

// New refunds
export async function insertNewRefundGeneralData(payload: {
  datetime: string
  clientCode: string
  clientName: string
  type: string
  finished: boolean
  numdev: string
  email: string
}): Promise<number> {
  const db = await getConnection()

  const transactions: Transaction[] = [
    {
      statement:
        'INSERT INTO pending_refunds (datetime, clientCode, clientName, type, numdev, finished, email) VALUES (?, ?, ?, ?, ?, ?, ?)',
      values: [
        payload.datetime,
        payload.clientCode,
        payload.clientName,
        payload.type,
        payload.numdev,
        payload.finished,
        payload.email
      ]
    }
  ]

  await db.executeSet(transactions)

  const dbsqLiteValues = await db.query('SELECT last_insert_rowid() as id')

  return dbsqLiteValues.values?.[0].id
}

export async function getNewRefundLineById(id: number): Promise<Line | null> {
  const db = await getConnection()

  const dbsqLiteValues = await db.query(`SELECT *
                                         FROM pending_refunds_lines
                                         WHERE id = ${id}`)

  if (dbsqLiteValues.values && dbsqLiteValues.values.length > 0) {
    return dbsqLiteValues.values[0]
  } else {
    return null
  }
}

export async function insertNewRefundLine(newRefundId: number, line: Line): Promise<void> {
  const db = await getConnection()

  const transactions: Transaction[] = [
    {
      statement:
        'INSERT INTO pending_refunds_lines (idRefund, ean, nc, description, lotNumber, expirationDate, createdLot, lotImage, units, price, depreciation, amount, reason, destination, sorting, eanReplacement, ncReplacement, unitsReplacement, descriptionReplacement) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
      values: [
        newRefundId,
        line.ean,
        line.nc,
        line.description,
        line.lotNumber,
        line.expirationDate,
        line.createdLot,
        line.lotImage,
        line.units,
        line.price,
        line.depreciation,
        line.amount,
        line.reason,
        line.destination,
        line.sorting,
        line.eanReplacement,
        line.ncReplacement,
        line.unitsReplacement,
        line.descriptionReplacement
      ]
    }
  ]

  await db.executeSet(transactions)
}

export async function updateNewRefundLine(line: Line): Promise<void> {
  const db = await getConnection()

  const transactions: Transaction[] = [
    {
      statement:
        'UPDATE pending_refunds_lines SET ean = ?, nc = ?, description = ?, lotNumber = ?, expirationDate = ?, createdLot = ?, lotImage = ?, units = ?, price = ?, depreciation = ?, amount = ?, reason = ?, destination = ?, sorting = ?, eanReplacement = ?, ncReplacement = ?, unitsReplacement = ?, descriptionReplacement = ? WHERE id = ?',
      values: [
        line.ean,
        line.nc,
        line.description,
        line.lotNumber,
        line.expirationDate,
        line.createdLot,
        line.lotImage,
        line.units,
        line.price,
        line.depreciation,
        line.amount,
        line.reason,
        line.destination,
        line.sorting,
        line.eanReplacement,
        line.ncReplacement,
        line.unitsReplacement,
        line.descriptionReplacement,
        line.id
      ]
    }
  ]

  await db.executeSet(transactions)
}

export async function deleteNewRefundLine(lineId: number): Promise<void> {
  const db = await getConnection()

  await db.executeSet([
    {
      statement: 'DELETE FROM pending_refunds_lines WHERE id = ?',
      values: [lineId]
    }
  ])
}

export async function updateNewRefundSignatures(
  newRefundId: number,
  signatures: {
    clientSignature: string | null
    userSignature: string | null
  }
): Promise<void> {
  const db = await getConnection()

  const transactions: Transaction[] = [
    {
      statement: 'UPDATE pending_refunds SET clientSignature = ?, userSignature = ? WHERE id = ?',
      values: [signatures.clientSignature, signatures.userSignature, newRefundId]
    }
  ]

  await db.executeSet(transactions)
}

export async function updateNewRefundAttachments(
  newRefundId: number,
  attachments: {
    attachment1: string | null
    attachment2: string | null
    attachment3: string | null
  }
): Promise<void> {
  const db = await getConnection()

  const transactions: Transaction[] = [
    {
      statement: 'UPDATE pending_refunds SET attachment1 = ?, attachment2 = ?, attachment3 = ? WHERE id = ?',
      values: [attachments.attachment1, attachments.attachment2, attachments.attachment3, newRefundId]
    }
  ]

  await db.executeSet(transactions)
}

export async function updateNewRefundTagCodes(
  newRefundId: number,
  tagCodes: {
    packageQuantity: number
    codes: string
  }
): Promise<void> {
  const db = await getConnection()

  const transactions: Transaction[] = [
    {
      statement: 'UPDATE pending_refunds SET packageQuantity = ?, tagCodes = ? WHERE id = ?',
      values: [tagCodes.packageQuantity, tagCodes.codes, newRefundId]
    }
  ]

  await db.executeSet(transactions)
}

export async function updateNewRefundVirtual(newRefundId: number, virtual: boolean): Promise<void> {
  const db = await getConnection()

  const transactions: Transaction[] = [
    {
      statement: 'UPDATE pending_refunds SET virtual = ? WHERE id = ?',
      values: [virtual, newRefundId]
    }
  ]

  await db.executeSet(transactions)
}

export async function updateNewRefundAdditionalData(
  newRefundId: number,
  additionalData: {
    email: string | null
    authorization: boolean
    wholesaler: string | null
    observation: string | null
    comment: string | null
  }
): Promise<void> {
  const db = await getConnection()

  const transactions: Transaction[] = [
    {
      statement:
        'UPDATE pending_refunds SET email = ?, authorization = ?, wholesaler = ?, observation = ?, comment = ?, finished = ? WHERE id = ?',
      values: [
        additionalData.email,
        additionalData.authorization,
        additionalData.wholesaler,
        additionalData.observation,
        additionalData.comment,
        true,
        newRefundId
      ]
    }
  ]

  await db.executeSet(transactions)
}

export async function deleteRefund(id: number): Promise<void> {
  const db = await getConnection()

  await db.executeSet([
    {
      statement: 'DELETE FROM pending_refunds WHERE id = ?',
      values: [id]
    },
    {
      statement: 'DELETE FROM pending_refunds_lines WHERE idRefund = ?',
      values: [id]
    }
  ])
}

// only for testing
export async function exportDatabase(): Promise<void> {
  if (Capacitor.getPlatform() !== 'web') {
    return
  }

  const db = localForage.createInstance({
    name: 'jeepSqliteStore',
    storeName: 'databases'
  })

  const data = await db.getItem<Uint8Array>('qadux360SQLite.db')
  if (!data) {
    return
  }

  initiateDownload('quadux.db', 'application/vnd.sqlite3', data)
}

export function initiateDownload(filename: string, dataType: string, data: BlobPart) {
  const blob = new Blob([data], {type: dataType})
  const a = document.createElement('a')
  a.href = window.URL.createObjectURL(blob)
  a.download = filename
  a.click()
}
