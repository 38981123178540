import {CronJob} from 'cron'

import {useSyncStore} from '@/store/sync'

export const syncJob = new CronJob(`* * * * *`, async () => {
  const syncStore = useSyncStore()
  if (syncStore.canSync()) {
    await syncStore.performSync()
  }
})
